import React from 'react';
import SEO from '../components/shared/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404 🛑" />
    <h1>Nu har vi hamnat fel</h1>
    <p>Här finns inget att visa...</p>
  </>
);

export default NotFoundPage;
